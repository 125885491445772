import React, { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import OffCanvas from './OffCanvas';
import SidebarHeader from './SidebarHeader';
import { BsLinkedin } from "react-icons/bs";

import { GetUserRoles } from '../../../utils/auth.utils';
import BottomNavigation from '../BottomsNavigation';
import ScrollTopComponent from '../../../utils/ScrollTopComponent';
const DashboardLayout = ({ setSelectedRange, selectedRange, setShowImage, setProgress, setSelectedCampagins, selectedCampagins }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const containerRef = useRef(null);
    const UserRole = GetUserRoles()
    const handleShowSidebar = () => setShowSidebar(true);
    const handleCloseSidebar = () => setShowSidebar(false);
    return (
        <div className='d-flex flex-column '>
            <div className='d-lg-flex' >
                <div className="d-none d-lg-block min-vh-100 sidebar" style={{ overflow: 'auto', height: '100vh', width: "250px" }}>
                    <Sidebar />
                </div>
                <div className='d-flex flex-column' style={{ flex: 1 }}>
                    <div ref={containerRef} className='' style={{ overflow: 'auto', height: '100vh' }}>
                        <div className='px-md-3 py-md-2'>
                            <SidebarHeader onLogoClick={handleShowSidebar} setSelectedRange={setSelectedRange} selectedRange={selectedRange} setShowImage={setShowImage} setProgress={setProgress} selectedCampagins={selectedCampagins} setSelectedCampagins={setSelectedCampagins} />
                        </div>
                        <Outlet />
                        {UserRole !== 'admin' && (
                            <div className='d-lg-block d-none'>
                                <footer className="d-flex px-lg-3 ps-2 py-1 text-white justify-content-between align-items-center mb-lg-0 footer-bottom" style={{ background: '#007755' }}>
                                    <div className="pe-5 text-small">
                                        <small>Copyright &copy; 2024 KyPI -
                                            26 Rue Bosquet, 75007 Paris // R.Santa Cruz,2187 - Vila Mariana, São Paulo, SP Brazil
                                        </small>
                                    </div>
                                    <ul className="list-unstyled d-flex justify-content-start pb-0 mb-0">
                                        <li><a href="https://www.linkedin.com/company/kypi/" target="_blank" className="text-white li-footer me-3"><BsLinkedin size={20} /></a></li>
                                    </ul>
                                </footer>
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <OffCanvas showSidebar={showSidebar} handleCloseSidebar={handleCloseSidebar} />
            <ScrollTopComponent containerRef={containerRef} />
            <div className="d-block d-lg-none">
                <BottomNavigation />
            </div>
        </div>
    );
}
export default DashboardLayout;
