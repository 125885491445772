import React from 'react';
import { Modal, Row, Col, Form, Button, Spinner } from 'react-bootstrap';

const CompaniesModal = ({ show, onHide, companies, loading, selectedCompany, setSelectedCompany }) => {

    const handleCompanyChange = (event) => {
        const selectedId = event.target.value;
        const selectedCompanyDetails = companies.find(company => company._id === selectedId);
        if (selectedCompanyDetails) {
            setSelectedCompany(selectedId);
        }
    };

    const handleSubmit = () => {
        if (selectedCompany) {
            const selectedCompanyDetails = companies.find(company => company._id === selectedCompany);
            localStorage.setItem('selectedCompany', selectedCompany);
            const userData = JSON.parse(localStorage.getItem('user'));
            if (userData) {
                userData.logo = selectedCompanyDetails.logo;
                userData.siteIDShow = selectedCompanyDetails.siteIDShow;
                userData.overviewPerDay = selectedCompanyDetails.overviewPerDay;
                userData.showgeo = selectedCompanyDetails.showgeo;
                userData.cityFranceMap = selectedCompanyDetails.cityFranceMap;
                userData.companyName = selectedCompanyDetails.name;
                userData.showExport = selectedCompanyDetails.showExport;
                userData.showExportTable = selectedCompanyDetails.showExportTable;
                userData.showCompaignFilter = selectedCompanyDetails.showCompaignFilter;

                localStorage.setItem('user', JSON.stringify(userData));
                window.dispatchEvent(new Event('user-update'));
            }

            console.log('Updated User Logo:', selectedCompanyDetails.logo);
            console.log('Selected Company ID:', selectedCompany);
            onHide();
        } else {
            alert('Please select a company before submitting.');
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg" className='py-0 my-0' keyboard={false} backdrop="static">
            <Modal.Header className='border-0 mb-0 pb-0' closeButton />
            {loading ? (
                <div className="d-flex justify-content-center align-items-center p-5">
                    <Spinner animation="border" role="status" style={{ color: "var(--color1)", }}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    <Modal.Header className='border-0' />
                    <Form className='pb-3 px-4'>
                        <h3 className='text-center mb-4 text-box' style={{ color: "var(--primary-color)" }}>Select Accounts</h3>
                        <Row className='py-3'>
                            <Col className='company-select'>

                                <Form.Group controlId="companySelect">
                                    <Form.Label>Select an account</Form.Label>
                                    <Form.Select
                                        size='lg'
                                        value={selectedCompany}
                                        onChange={handleCompanyChange}
                                    >
                                        <option value="">Choose...</option>
                                        {companies?.map(company => (
                                            <option key={company?._id} value={company?._id}>
                                                {company?.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-end pt-2 text-end">
                            <Button type="button" className='border-0 px-4 py-2 company-btn-hover' onClick={handleSubmit} disabled={loading || !selectedCompany} style={{ backgroundColor: 'var( --color1)', color: 'var( --color2)' }}>
                                {loading ? 'submiting...' : 'Submit'}
                            </Button>
                        </div>
                    </Form>
                </>
            )}

        </Modal>
    );
};

export default CompaniesModal;
