import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { AditionalInfo, GetAttributeValues } from "../../utils/admin/admin.service";
import Swal from "sweetalert2";
import BudgetSelection from "../additionalForms/BudgetSelection";
import MetricSelection from "../additionalForms/MetricSelection";
import FTDUniqueSection from "../additionalForms/FTDUniqueSection";
import CategorySelection from "../additionalForms/CategorySelection";
import EventSelection from "../additionalForms/EventSelection";
import SecondMetricSelection from "../additionalForms/SecondMetricSelection";
import ShowSelection from "../additionalForms/ShowSelection";
import DayDistributionSelection from "../additionalForms/DayDistributionSelection";
import AttributeTouchSelection from "../additionalForms/AttributeTouchSelection";
import CountrySelection from "../additionalForms/CountrySelection";
import GeoPercentageInput from "../additionalForms/GeoPercentageInput";
import BudgetTabsSelection from "../additionalForms/BudgetTabsSelection";
import ExcludeAppId from "../additionalForms/ExcludeAppId";
import ExcludeCreatives from "../additionalForms/ExcludeCreatives";
import ExtraColumnTable from "../additionalForms/ExtraColumnTable";

const AdditionalDetailModal = ({ show, onHide, company, additional, reCall }) => {
    console.log("Company", company)
    const [formData, setFormData] = useState({
        ftd: company?.ftd ?? false,
        appId: company?.appId ?? false,
        showCompaignFilter: company?.showCompaignFilter ?? false,
        extraColumnPlace: company?.extraColumnPlace ?? false,
        overviewPerDay: company?.overviewPerDay ?? false,
        showExport: company?.showExport ?? false,
        showExportTable: company?.showExport ?? false,
        showgeo: company?.showgeo ?? false,
        creativeFtd: company?.creativeFtd ?? false,
        appName: company?.appName ?? false,
        cityFranceMap: company.cityFranceMap,
        id: company?._id,
        creativeColumn: company?.creativeColumn,
        categoryShow: company?.categoryShow || [],
        eventsShow: company?.eventsShow || [],
        excludeAppId: company?.excludeAppId || [],
        budgetTabs: company?.budgetTabs || [],
        ftdUnique: company?.ftdUnique || { label: "FTD's (unique)", name: 'Event Name', value: 'af_first_deposit', select: 'count' },
        budget: company?.budget || { name: 'Event Name', value: 'af_first_deposit', count: 50, currency: '€', select: 'count' },
        budget2: company?.budget2 || { name: 'Event Name', value: 'af_first_deposit', count: 16, select1: 'count', name2: 'Event Name', value2: 'af_complete_registration', count2: 2, select2: 'count', currency: '€' },
        selectedMatrics: (company?.selectedMatrics && company.selectedMatrics.length > 0)
            ? company.selectedMatrics
            : [
                { label: 'Budget', name: '', value: '', select: 'count' },
                { label: 'Installs', name: 'Installs', value: '', select: 'sum' },
                { label: 'Signed', name: 'Event Name', value: 'af_complete_registration', select: 'count' },
                { label: "FTD's (unique)", name: 'Event Name', value: 'af_first_deposit', select: 'count' },
            ],
        extraTableColumn: (company?.extraTableColumn && company.extraTableColumn.length > 0)
            ? company.extraTableColumn
            : [],
        metrics: company?.metrics || [
            { label: 'FTD’s (unique)', name: 'Event Name', value: 'af_first_deposit', select: 'count' },
        ],
        countrySelection: company?.countrySelection || { label: 'FR', value: 100 },
        dayDistribution: company?.dayDistribution || { name: 'Event Name', value: 'af_first_deposit', select: 'count' },
        attributeTouch: company?.attributeTouch || { label: '%FTD’s Attributed touch', name: 'Attributed Touch Type', value: [] },
        geoPercentage: company?.geoPercentage || { high: 5, low: 2 },
        categoryPercentage: company?.categoryPercentage || { high: 5, low: 2 },
        perDayPercentage: company?.perDayPercentage || { high: 5, low: 2 },
        countryDistribution: company?.countryDistribution || [],
        creativeFilter: company?.creativeFilter || [],
    });

    const [loading, setLoading] = useState(false);
    const [selectedBudget, setSelectedBudget] = useState("budget");
    const [attributeOptions, setAttributeOptions] = useState([]);
    const [valueOptions, setValueOptions] = useState([]);
    const [valueOptions1budget2, setValueOptions1budget2] = useState([]);
    const [valueOptions2budget2, setValueOptions2budget2] = useState([]);
    const [valueOptionsFtd, setValueOptionsFtd] = useState([]);
    const [valueOptionsDay, setValueOptionsDay] = useState([]);
    const [valueOptionsAttribute, setValueOptionsAttribute] = useState([]);
    const [matricValueOptions, setMatricValueOptions] = useState({});
    const [columnValueOptions, setColumnValueOptions] = useState({});
    const [valueOptionsmetric, setValueOptionsmetric] = useState([]);

    useEffect(() => {
        if (additional) {
            const attributes = additional.attributesArray.map(attr => ({ value: attr, label: attr }));
            setAttributeOptions(attributes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additional]);
    const fetchAttributeValuesbudget = async (selectedAttribute, name) => {
        if (selectedAttribute) {
            Swal.fire({ title: "Processing...", text: "Please wait data is loading.", allowOutsideClick: false });
            Swal.showLoading();

            try {
                const response = await GetAttributeValues(company._id, selectedAttribute);
                const values = response?.data?.values.map(value => ({ value, label: value }));
                if (name === 'ftd') {
                    setValueOptionsFtd(values);
                } else if (name === 'name') {
                    setValueOptions(values)
                } else if (name === 'metric') {
                    setValueOptionsmetric(values);
                } else if (name === 'name1') {
                    setValueOptions1budget2(values);
                } else if (name === 'name2') {
                    setValueOptions2budget2(values);
                } else if (name === 'day') {
                    setValueOptionsDay(values);
                } else if (name === 'attribute') {
                    setValueOptionsAttribute(values)
                }
            } catch (error) {
                Swal.fire("Error!", "Failed to fetch values.", "error");
            } finally {
                Swal.close();
            }
        } else {
            if (name === 'ftd') {
                setValueOptionsFtd([]);
            } else if (name === 'name') {
                setValueOptions([])
            } else if (name === 'metric') {
                setValueOptionsmetric([]);
            } else if (name === 'name1') {
                setValueOptions1budget2([]);
            } else if (name === 'name2') {
                setValueOptions2budget2([]);
            } else if (name === 'day') {
                setValueOptionsDay([]);
            } else if (name === 'attribute') {
                setValueOptionsAttribute([])
            }
        }
    };
    useEffect(() => {
        let loadingCounter = 0;
        setSelectedBudget(company?.budget?.name ? 'budget' : 'budget2');
        const startLoading = () => {
            Swal.fire({
                title: "Processing...",
                text: "Please wait, data is loading.",
                allowOutsideClick: false,
                didOpen: () => { Swal.showLoading(); }
            });
        };

        const stopLoading = () => {
            loadingCounter--;
            if (loadingCounter === 0) {
                Swal.close();
            }
        };

        const fetchAllData = async () => {
            if (company?.metrics?.name) {
                loadingCounter++;
                await fetchAttributeValuesbudget(company?.metrics?.name, "metric");
                stopLoading();
            }
            if (company?.ftdUnique?.name) {
                loadingCounter++;
                await fetchAttributeValuesbudget(company?.ftdUnique?.name, "ftd");
                stopLoading();
            }
            if (company?.dayDistribution?.name) {
                loadingCounter++;
                await fetchAttributeValuesbudget(company?.dayDistribution?.name, "day");
                stopLoading();
            }
            if (company?.attributeTouch?.name) {
                console.log("Company attribute", company?.attributeTouch?.name)
                loadingCounter++;
                await fetchAttributeValuesbudget(company?.attributeTouch?.name, "attribute");
                stopLoading();
            }
            if (company?.budget?.name) {
                loadingCounter++;
                await fetchAttributeValuesbudget(company?.budget?.name, "name");
                stopLoading();
            }
            if (company?.budget2?.name) {
                loadingCounter++;
                await fetchAttributeValuesbudget(company?.budget2?.name, "name1");
                stopLoading();
            }
            if (company?.budget2?.name2) {
                loadingCounter++;
                await fetchAttributeValuesbudget(company?.budget2?.name2, "name2");
                stopLoading();
            }
            if (company?.selectedMatrics) {
                formData.selectedMatrics.forEach(async (metric, index) => {
                    if (metric?.name) {
                        loadingCounter++;
                        await fetchAttributeValuesMatrics(metric?.name, index);
                        stopLoading();
                    }
                });
            }

            if (loadingCounter > 0) {
                startLoading();
            }
        };

        fetchAllData();
        console.log("APtions", formData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    const fetchAttributeValuesMatrics = async (selectedAttribute, matricIndex) => {
        if (selectedAttribute) {
            Swal.fire({ title: "Processing...", text: "Fetching attribute values.", allowOutsideClick: false });
            Swal.showLoading();
            try {
                const response = await GetAttributeValues(company._id, selectedAttribute);
                const values = response?.data?.values.map(value => ({ value, label: value }));

                setMatricValueOptions(prev => ({ ...prev, [matricIndex]: values }));

            } catch (error) {
                Swal.fire("Error!", "Failed to fetch values.", "error");
                setMatricValueOptions(prev => ({ ...prev, [matricIndex]: [] }));
            } finally {
                Swal.close();
            }
        } else {
            setMatricValueOptions(prev => ({ ...prev, [matricIndex]: [] }));
        }
    };
    const fetchAttributeValuesColumns = async (selectedAttribute, columnIndex) => {
        if (selectedAttribute) {
            Swal.fire({ title: "Processing...", text: "Fetching attribute values.", allowOutsideClick: false });
            Swal.showLoading();
            try {
                const response = await GetAttributeValues(company._id, selectedAttribute);
                const values = response?.data?.values.map(value => ({ value, label: value }));

                setColumnValueOptions(prev => ({ ...prev, [columnIndex]: values }));

            } catch (error) {
                Swal.fire("Error!", "Failed to fetch values.", "error");
                setColumnValueOptions(prev => ({ ...prev, [columnIndex]: [] }));
            } finally {
                Swal.close();
            }
        } else {
            setColumnValueOptions(prev => ({ ...prev, [columnIndex]: [] }));
        }
    };
    const handleSelectMatricsChange = (index, field, value) => {
        const updatedMatrics = [...formData.selectedMatrics];
        if (field === 'name') {
            updatedMatrics[index].value = '';
        }
        updatedMatrics[index][field] = value;
        setFormData(prev => ({
            ...prev,
            selectedMatrics: updatedMatrics,
        }));

        if (field === 'name') {
            fetchAttributeValuesMatrics(value, index);
        }
    };
    const handleSelectColumnsChange = (index, field, value) => {
        const updatedColumns = [...formData.extraTableColumn];
        if (field === 'name') {
            updatedColumns[index].value = '';
        }
        updatedColumns[index][field] = value;
        setFormData(prev => ({
            ...prev,
            extraTableColumn: updatedColumns,
        }));

        if (field === 'name') {
            fetchAttributeValuesColumns(value, index);
        }
    };
    const fetchAttributeValues = async (selectedAttribute, updateFn, setValueOptions) => {
        if (selectedAttribute) {
            Swal.fire({ title: "Processing...", text: "Fetching attribute values.", allowOutsideClick: false });
            Swal.showLoading();

            try {
                const response = await GetAttributeValues(company._id, selectedAttribute);
                const values = response?.data?.values.map(value => ({ value, label: value }));
                setValueOptions(values);
                updateFn(selectedAttribute, '');
            } catch (error) {
                updateFn(selectedAttribute, '');
                Swal.fire("Error!", "Failed to fetch values.", "error");
            } finally {
                Swal.close();
            }
        } else {
            setValueOptions([]);
            updateFn(selectedAttribute, '');
        }
    };

    const handleAttributeChange = (e) => {
        const selectedAttribute = e.target.value;
        fetchAttributeValues(selectedAttribute, (name, value) => setFormData(prev => ({
            ...prev, budget: { ...prev.budget, name, value }
        })), setValueOptions);
    };

    const handleAttributeChange1budget2 = (e) => {
        const selectedAttribute = e.target.value;
        fetchAttributeValues(selectedAttribute, (name, value) => setFormData(prev => ({
            ...prev,
            budget2: {
                ...prev.budget2,
                name,
                value
            }
        })), setValueOptions1budget2);
    };

    const handleAttributeChange2budget2 = (e) => {
        const selectedAttribute = e.target.value;
        fetchAttributeValues(selectedAttribute, (name2, value2) => setFormData(prev => ({
            ...prev,
            budget2: {
                ...prev.budget2,
                name2,
                value2
            }
        })), setValueOptions2budget2);
    };

    const handleAttributeChangeFtd = (e) => {
        const selectedAttribute = e.target.value;
        fetchAttributeValues(selectedAttribute, (name, value) => setFormData(prev => ({
            ...prev, ftdUnique: { name, value }
        })), setValueOptionsFtd);
    };

    const handleAttributeChangeDay = (e) => {
        const selectedAttribute = e.target.value;
        fetchAttributeValues(selectedAttribute, (name, value) => setFormData(prev => ({
            ...prev, dayDistribution: { name, value }
        })), setValueOptionsDay);
    };
    const handleAttributeTouch = (e) => {
        const selectedAttribute = e.target.value;
        fetchAttributeValues(selectedAttribute, (name, value) => setFormData(prev => ({
            ...prev, attributeTouch: { name, value }
        })), setValueOptionsAttribute);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const filteredExtraColumn = Array.isArray(formData?.extraTableColumn)
            ? formData.extraTableColumn.filter((column) => column?.name?.trim())
            : [];
        const updatedFormData = {
            ...formData,
            extraTableColumn: filteredExtraColumn,
            budget: selectedBudget === 'budget' ? formData.budget : undefined,
            budget2: selectedBudget === 'budget2' ? formData.budget2 : undefined,
        };
        console.log("Form data", updatedFormData)
        Swal.fire({
            title: "Are you sure?",
            text: "You want to edit this additional information.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1cc76d",
            cancelButtonColor: "#6C757D",
            confirmButtonText: "Yes, edit it!",
            cancelButtonText: "No, cancel",
            allowOutsideClick: false,
            preConfirm: () => {
                Swal.showLoading();
                return new Promise(async (resolve, reject) => {
                    try {
                        await AditionalInfo(updatedFormData);
                        resolve();
                    } catch (error) {
                        setLoading(false);
                        reject(error?.response?.data?.error || 'Network Error');
                    }
                });
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Updated!",
                        text: "The additional info has been edited successfully and added to the company.",
                        icon: "success",
                        confirmButtonColor: "#28a745",
                    });
                    onHide();
                    reCall();
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error || 'An error occurred while updating the additional information.',
                    icon: "error",
                    confirmButtonColor: "#dc3545",
                });
            });
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg" className='py-0 my-0' keyboard={false} backdrop="static">
            <Modal.Header className='border-0 mb-0 pb-0' closeButton />
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <h3 className='text-center text-box pb-2' style={{ color: "var(--primary-color)" }}>Edit Additional Info Company</h3>
                    <ShowSelection
                        company={company}
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <GeoPercentageInput
                        company={company}
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <CategorySelection
                        formData={formData}
                        company={company}
                        setFormData={setFormData}
                        additional={additional}
                    />
                    <ExcludeCreatives
                        formData={formData}
                        company={company}
                        setFormData={setFormData}
                        additional={additional}
                    />
                    <ExcludeAppId
                        formData={formData}
                        company={company}
                        setFormData={setFormData}
                        additional={additional}
                    />
                    <EventSelection
                        formData={formData}
                        setFormData={setFormData}
                        additional={additional}
                    />
                    <CountrySelection
                        formData={formData}
                        setFormData={setFormData}
                        additional={additional}
                    />
                    <DayDistributionSelection
                        formData={formData}
                        setFormData={setFormData}
                        attributeOptions={attributeOptions}
                        handleAttributeChangeDay={handleAttributeChangeDay}
                        valueOptionsDay={valueOptionsDay}
                    />
                    <AttributeTouchSelection
                        formData={formData}
                        setFormData={setFormData}
                        attributeOptions={attributeOptions}
                        handleAttributeTouch={handleAttributeTouch}
                        valueOptionsAttribute={valueOptionsAttribute}
                    />
                    <FTDUniqueSection
                        formData={formData}
                        valueOptionsFtd={valueOptionsFtd}
                        attributeOptions={attributeOptions}
                        handleAttributeChangeFtd={handleAttributeChangeFtd}
                        setFormData={setFormData}
                    />

                    <BudgetSelection
                        selectedBudget={selectedBudget}
                        setSelectedBudget={setSelectedBudget}
                        formData={formData}
                        handleAttributeChange={handleAttributeChange}
                        handleAttributeChange1budget2={handleAttributeChange1budget2}
                        handleAttributeChange2budget2={handleAttributeChange2budget2}
                        valueOptions={valueOptions}
                        valueOptions1budget2={valueOptions1budget2}
                        valueOptions2budget2={valueOptions2budget2}
                        attributeOptions={attributeOptions}
                        setFormData={setFormData}
                    />
                    <BudgetTabsSelection
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <MetricSelection
                        formData={formData}
                        attributeOptions={attributeOptions}
                        matricValueOptions={matricValueOptions}
                        handleSelectMatricsChange={handleSelectMatricsChange}
                        selectedBudget={selectedBudget}
                        company={company}
                        setFormData={setFormData}
                    />
                    <SecondMetricSelection
                        formData={formData}
                        fetchAttributeValuesbudget={fetchAttributeValuesbudget}
                        attributeOptions={attributeOptions}
                        setFormData={setFormData}
                        valueOptionsmetric={valueOptionsmetric}
                    />
                    <ExtraColumnTable
                        formData={formData}
                        attributeOptions={attributeOptions}
                        columnValueOptions={columnValueOptions}
                        handleSelectColumnsChange={handleSelectColumnsChange}
                        setFormData={setFormData}
                    />
                    <div className="d-flex justify-content-between pt-2 mt-3">
                        <Button onClick={onHide} className='bg-secondary border-0 px-4 py-2' disabled={loading}>Cancel</Button>
                        <Button type="submit" className='bg-primary-ltr border-0 px-4 py-2 submit-btn-hover' disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AdditionalDetailModal;
