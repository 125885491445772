import React, { useState } from 'react';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';
import AdditionalDetailModal from '../modals/AdditionalDetailModal';

const AdditionalDetail = ({ company, additional, reCall }) => {
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditClick = (e) => {
        e.preventDefault();
        setShowEditModal(true);
    };

    const handleModalClose = () => {
        setShowEditModal(false);
    };

    const getColorStyles = (index) => {
        const colors = ["var(--color1)", "var(--color2)", "var(--color3)"];
        const selectedColor = colors[index % colors?.length];

        const textColorMap = {
            "var(--color1)": "#09332c",
            "var(--color2)": "#FFFFFF",
            "var(--color3)": "#FFFFFF"
        };

        return {
            backgroundColor: `${selectedColor} !important`,
            color: textColorMap[selectedColor] || "#000000",
        };
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <h4 style={{ color: '#4E4E4E' }} className="my-4">
                    Additional Company Info
                </h4>
                <Button
                    className="btn-custom px-3 fw-bold d-flex align-items-center"
                    onClick={handleEditClick}
                    style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
                >
                    <span className="me-2">&#9998;</span>
                    Edit Additional Info
                </Button>
            </div>
            <Card className="mb-4 border-0 shadow" style={{ border: 'unset' }}>
                <Card.Body>
                    <Row className='p-4'>
                        <Card.Text as='h4' className="fw-bold text-secondary">Additional Information</Card.Text>
                        <Col md={12} className="">
                            <div className='p-2'>
                                <Row className='pb-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Show Map</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            <Badge bg="success" className="ms-2">{company?.cityFranceMap}</Badge>
                                        </span>
                                    </Col>
                                </Row>
                                {company?.siteIDShow &&
                                    <>
                                        <Row className='pb-2'>
                                            <Col sm={3}>
                                                <Card.Text>
                                                    <strong className='info-head'>Show App Name</strong>
                                                </Card.Text>
                                            </Col>
                                            <Col sm={8}>
                                                <span className='text-secondary'>
                                                    {company.appName ? (
                                                        <Badge bg="success" className="ms-2">True</Badge>
                                                    ) : (
                                                        <Badge bg="danger" className="ms-2">False</Badge>
                                                    )}
                                                </span>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Row className='pb-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Show Campaign Filter</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company.showCompaignFilter ? (
                                                <Badge bg="success" className="ms-2">True</Badge>
                                            ) : (
                                                <Badge bg="danger" className="ms-2">False</Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='pb-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Show Main Event</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.ftd ? (
                                                <Badge bg="success" className="ms-2">True</Badge>
                                            ) : (
                                                <Badge bg="danger" className="ms-2">False</Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                {company?.extraTableColumn?.length !== 0 &&
                                    <Row className='pb-2'>
                                        <Col sm={3}>
                                            <Card.Text>
                                                <strong className='info-head'>Show Extra columns Before Main Event</strong>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={8}>
                                            <span className='text-secondary'>
                                                {company?.extraColumnPlace ? (
                                                    <Badge bg="success" className="ms-2">True</Badge>
                                                ) : (
                                                    <Badge bg="danger" className="ms-2">False</Badge>
                                                )}
                                            </span>
                                        </Col>
                                    </Row>
                                }
                                <Row className='pb-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Show Exporting table</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.showExportTable ? (
                                                <Badge bg="success" className="ms-2">True</Badge>
                                            ) : (
                                                <Badge bg="danger" className="ms-2">False</Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Show AppId</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.appId ? (
                                                <Badge bg="success" className="ms-2">True</Badge>
                                            ) : (
                                                <Badge bg="danger" className="ms-2">False</Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Show Overview Per Day Tab</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.overviewPerDay ? (
                                                <Badge bg="success" className="ms-2">True</Badge>
                                            ) : (
                                                <Badge bg="danger" className="ms-2">False</Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Show Geo's Tab</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.showgeo ? (
                                                <Badge bg="success" className="ms-2">True</Badge>
                                            ) : (
                                                <Badge bg="danger" className="ms-2">False</Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Show Export Pdf</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.showExport ? (
                                                <Badge bg="success" className="ms-2">True</Badge>
                                            ) : (
                                                <Badge bg="danger" className="ms-2">False</Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Use Main Event's(sum) in creative</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.creativeFtd ? (
                                                <Badge bg="success" className="ms-2">True</Badge>
                                            ) : (
                                                <Badge bg="danger" className="ms-2">False</Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                {company?.siteIDShow &&
                                    <>
                                        <Row className='py-2'>
                                            <Col sm={3}>
                                                <Card.Text>
                                                    <strong className='info-head'>Category Shown</strong>
                                                </Card.Text>
                                            </Col>
                                            <Col sm={8}>
                                                <span className='text-secondary'>
                                                    {company?.categoryShow && company?.categoryShow?.length > 0 ? (
                                                        company?.categoryShow?.map((category, index) => {
                                                            const { backgroundColor, color } = getColorStyles(index);
                                                            return (
                                                                <Badge
                                                                    key={index}
                                                                    style={{
                                                                        backgroundColor,
                                                                        color,
                                                                    }}
                                                                    className="ms-2"
                                                                >
                                                                    {category}
                                                                </Badge>
                                                            );
                                                        })
                                                    ) : (
                                                        <span className='text-secondary'>Default</span>
                                                    )}
                                                </span>
                                            </Col>
                                        </Row>

                                        <Row className='py-2'>
                                            <Col sm={3}>
                                                <Card.Text>
                                                    <strong className='info-head'>AppIDs Filters</strong>
                                                </Card.Text>
                                            </Col>
                                            <Col sm={8}>
                                                <span className='text-secondary'>
                                                    {company?.excludeAppId && company?.excludeAppId?.length > 0 ? (
                                                        company?.excludeAppId.map((appId, index) => {
                                                            const { backgroundColor, color } = getColorStyles(index);
                                                            return (
                                                                <Badge
                                                                    key={index}
                                                                    style={{
                                                                        backgroundColor,
                                                                        color,
                                                                    }}
                                                                    className="ms-2"
                                                                >
                                                                    {appId}
                                                                </Badge>
                                                            );
                                                        })
                                                    ) : (
                                                        <span className='text-secondary'>Default</span>
                                                    )}
                                                </span>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Creatives Filters</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.creativeFilter && company?.creativeFilter?.length > 0 ? (
                                                company?.creativeFilter.map((creative, index) => {
                                                    const { backgroundColor, color } = getColorStyles(index);
                                                    return (
                                                        <Badge
                                                            key={index}
                                                            style={{
                                                                backgroundColor,
                                                                color,
                                                            }}
                                                            className="ms-2"
                                                        >
                                                            {creative}
                                                        </Badge>
                                                    );
                                                })
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Graph Events Shown</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.eventsShow && company?.eventsShow?.length > 0 ? (
                                                company.eventsShow.map((event, index) => {
                                                    const { backgroundColor, color } = getColorStyles(index);
                                                    return (
                                                        <Badge
                                                            key={index}
                                                            style={{
                                                                backgroundColor,
                                                                color,
                                                            }}
                                                            className="ms-2"
                                                        >
                                                            {event}
                                                        </Badge>
                                                    );
                                                })
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Overview Per Day Percentage</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.perDayPercentage?.high ? (
                                                <>
                                                    <span
                                                        className='percentage-high'
                                                        style={{ color: 'var(--color1)' }}
                                                    >
                                                        {`> ${company.perDayPercentage.high}% `}
                                                    </span>
                                                    |
                                                    <span
                                                        className='percentage-mid'
                                                        style={{ color: 'var(--color2)', marginLeft: '8px' }}
                                                    >
                                                        {`${company.perDayPercentage.low}% - ${company.perDayPercentage.high}% `}
                                                    </span>
                                                    |
                                                    <span
                                                        className='percentage-low'
                                                        style={{ color: 'var(--color3)', marginLeft: '8px' }}
                                                    >
                                                        {`< ${company.perDayPercentage.low}%`}
                                                    </span>
                                                </>
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                {company?.siteIDShow &&
                                    <Row className='py-2'>
                                        <Col sm={3}>
                                            <Card.Text>
                                                <strong className='info-head'>Category Percentage</strong>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={8}>
                                            <span className='text-secondary'>
                                                {company?.categoryPercentage?.high ? (
                                                    <>
                                                        <span
                                                            className='percentage-high'
                                                            style={{ color: 'var(--color1)' }}
                                                        >
                                                            {`> ${company.categoryPercentage.high}% `}
                                                        </span>
                                                        |
                                                        <span
                                                            className='percentage-mid'
                                                            style={{ color: 'var(--color2)', marginLeft: '8px' }}
                                                        >
                                                            {`${company.categoryPercentage.low}% - ${company.categoryPercentage.high}% `}
                                                        </span>
                                                        |
                                                        <span
                                                            className='percentage-low'
                                                            style={{ color: 'var(--color3)', marginLeft: '8px' }}
                                                        >
                                                            {`< ${company.categoryPercentage.low}%`}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span className='text-secondary'>Default</span>
                                                )}
                                            </span>
                                        </Col>
                                    </Row>
                                }
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Geo Percentage</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.geoPercentage?.high ? (
                                                <>
                                                    <span
                                                        className='percentage-high'
                                                        style={{ color: 'var(--color1)' }}
                                                    >
                                                        {`> ${company.geoPercentage.high}% `}
                                                    </span>
                                                    |
                                                    <span
                                                        className='percentage-mid'
                                                        style={{ color: 'var(--color2)', marginLeft: '8px' }}
                                                    >
                                                        {`${company.geoPercentage.low}% - ${company.geoPercentage.high}% `}
                                                    </span>
                                                    |
                                                    <span
                                                        className='percentage-low'
                                                        style={{ color: 'var(--color3)', marginLeft: '8px' }}
                                                    >
                                                        {`< ${company.geoPercentage.low}%`}
                                                    </span>
                                                </>
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Ftd's(unique)</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.ftdUnique?.name ? (
                                                <>
                                                    {company?.ftdUnique?.label &&
                                                        <span className='text-dark'>{company?.ftdUnique?.label} | </span>
                                                    }
                                                    {`${company?.ftdUnique?.name}[${company?.ftdUnique?.value}]  (${company?.ftdUnique?.select || 'count'}`})
                                                </>
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Day Distribution</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.dayDistribution?.name ? (
                                                <>
                                                    {`${company?.dayDistribution?.name}[${company?.dayDistribution?.value}]  (${company?.dayDistribution?.select || 'count'}`})
                                                </>
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Country Distribution</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.countryDistribution && company?.countryDistribution?.length > 0 ? (
                                                company.countryDistribution.map((country, index) => {
                                                    const { backgroundColor, color } = getColorStyles(index);
                                                    return (
                                                        <Badge
                                                            key={index}
                                                            style={{
                                                                backgroundColor,
                                                                color,
                                                            }}
                                                            className="ms-2"
                                                        >
                                                            {country}
                                                        </Badge>
                                                    );
                                                })
                                            ) : (
                                                <Badge
                                                    style={{
                                                        backgroundColor: "var(--color1) !important",
                                                        color: "##09332c !important",
                                                    }}
                                                    className="ms-2 text-dark"
                                                >
                                                    {company?.countrySelection?.label}:{company?.countrySelection?.value}
                                                </Badge>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Attribute Touch Graph</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.attributeTouch?.name ? (
                                                <>
                                                    {company?.attributeTouch?.label &&
                                                        <span className='text-dark'>{company?.attributeTouch?.label} | </span>
                                                    }
                                                    {`${company?.attributeTouch?.name}[${company?.attributeTouch?.value}]`}
                                                </>
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>

                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Budget Formula</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.budget?.name ? (
                                                <span>
                                                    {`${company?.budget?.name}[${company?.budget?.value}] * ${company?.budget?.count} ${company?.budget?.currency}`}
                                                </span>
                                            ) : company?.budget2?.name ? (
                                                <>
                                                    {`(${company?.budget2?.name}[${company?.budget2?.value}] * ${company?.budget2?.count}) + (${company?.budget2?.name2}[${company?.budget2?.value2}] * ${company?.budget2?.count2}) ${company?.budget2?.currency}`}

                                                </>
                                            ) : (
                                                <span className='text-secondary'>Default</span>

                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Budget Shown in Tabs</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.budgetTabs && company.budgetTabs?.length > 0 ? (
                                                company.budgetTabs.map((budget, index) => {
                                                    const { backgroundColor, color } = getColorStyles(index);
                                                    return (
                                                        <Badge
                                                            key={index}
                                                            style={{
                                                                backgroundColor,
                                                                color,
                                                            }}
                                                            className="ms-2"
                                                        >
                                                            {budget}
                                                        </Badge>
                                                    );
                                                })
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                {company?.selectedMatrics &&
                                    company.selectedMatrics.map((metric, index) => (
                                        <Row className='py-2'>
                                            <Col sm={3}>
                                                <Card.Text>
                                                    <strong className='info-head'>Top Matrix {index + 1} </strong>
                                                </Card.Text>
                                            </Col>
                                            <Col sm={8}>
                                                <span className='text-secondary'>
                                                    {index === 0 ? (
                                                        company?.budget?.name ? (

                                                            <>
                                                                <span className='text-dark'>{metric?.label} | </span>
                                                                {`${company?.budget?.name}[${company?.budget?.value}] * ${company?.budget?.count} ${company?.budget?.currency}`}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className='text-dark'>{metric?.label} | </span>
                                                                {`(${company?.budget2?.name}[${company?.budget2?.value}] * ${company?.budget2?.count}) + (${company?.budget2?.name2}[${company?.budget2?.value2}] * ${company?.budget2?.count2}) ${company?.budget2?.currency}`}

                                                            </>
                                                        )
                                                    ) : (
                                                        <>
                                                            <span className='text-dark'>{metric?.label} | </span>
                                                            {`${metric?.name}[${metric?.value}]  (${metric?.select || 'count'}`})
                                                        </>
                                                    )}
                                                </span>
                                            </Col>
                                        </Row>
                                    ))}

                                {company?.extraTableColumn &&
                                    company.extraTableColumn.map((metric, index) => (
                                        <Row className='py-2'>
                                            <Col sm={3}>
                                                <Card.Text>
                                                    <strong className='info-head'>Extra Column {index + 1} </strong>
                                                </Card.Text>
                                            </Col>
                                            <Col sm={8}>
                                                <span className='text-secondary'>
                                                    <span className='text-dark'>{metric?.label} | </span>
                                                    {`${metric?.name}[${metric?.value}]  (${metric?.select || 'count'}`})

                                                </span>
                                            </Col>
                                        </Row>
                                    ))}
                                <Row className='py-2'>
                                    <Col sm={3}>
                                        <Card.Text>
                                            <strong className='info-head'>Metrics Tab metric</strong>
                                        </Card.Text>
                                    </Col>
                                    <Col sm={8}>
                                        <span className='text-secondary'>
                                            {company?.metrics?.name ? (
                                                <>
                                                    <span className='text-dark'>{company?.metrics?.label} | </span>
                                                    {`${company?.metrics?.name}[${company?.metrics?.value}] (${company?.metrics?.select || 'count'}`})
                                                </>
                                            ) : (
                                                <span className='text-secondary'>Default</span>
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <AdditionalDetailModal
                show={showEditModal}
                onHide={handleModalClose}
                company={company}
                additional={additional}
                reCall={reCall}
            />
        </>
    );
};

export default AdditionalDetail;
